import { createAction, props } from '@ngrx/store';
import { ReadyGGAchievementData, ReadyGGAchievementReward, ReadyGGUserAchievement } from '@readygg/ng-api';

export const loadAchievements = createAction(
  '[Achievements] Load Achievements',
);

export const loadAchievementsSuccess = createAction(
  '[Achievements] Load Achievements Success',
  props<{ achievements: ReadyGGAchievementData[] | undefined, userAchievements: ReadyGGUserAchievement[] | undefined }>()
);

export const loadAchievementsFailure = createAction(
  '[Achievements] Load Achievements Failure',
  props<{ error: any }>()
);

export const claimAchievement = createAction(
  '[Achievements] Claim Achievement',
  props<{ achievementId: string }>()
);

export const claimAchievementSuccess = createAction(
  '[Achievements] Claim Achievement Success',
  props<{ rewards: Array<ReadyGGAchievementReward>, rewardsHaveBeenGivenOut: boolean, achievementId: string }>()
);

export const claimAchievementFailure = createAction(
  '[Achievements] Claim Achievement Failure',
  props<{ error: any }>()
);

export const setAchievementToTrigger = createAction(
  '[Achievements] Set Achievement To Trigger',
  props<{ achievement: ReadyGGAchievementData | null }>()
);

export const triggerAchievement = createAction(
  '[Achievements] Trigger Achievement',
  props<{ achievementId: string }>()
);

export const triggerAchievementSuccess = createAction(
  '[Achievements] Trigger Achievement Success',
  props<{
    achievementId: string;
    rewardsHaveBeenGivenOut: boolean | null;
    rewards: Array<ReadyGGAchievementReward> | null;
  }>()
);

export const triggerAchievementFailure = createAction(
  '[Achievements] Trigger Achievement Failure',
  props<{ error: any }>()
);

export const AchievementsActions = {
  loadAchievements,
  loadAchievementsSuccess,
  loadAchievementsFailure,
  claimAchievement,
  claimAchievementSuccess,
  claimAchievementFailure,
  setAchievementToTrigger,
  triggerAchievement,
  triggerAchievementSuccess,
  triggerAchievementFailure,
}
